class goals {
    reach(name) {
        // console.log("Reach", name);
        if (window.ymEvent) {
            // console.log("Y");
            window.ymEvent(name);
        }
        if (window.gtag) {
            // console.log("G");
            gtag('event', name)
        }
    }
}

export default new goals();
